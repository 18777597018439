import {
  FIRST_ROW_CLASS,
  FORM_ROW_CLASS,
  FORM_ROW_TITLE_CLASS,
  FORM_FIELD_CLASS,
  FORM_FIELD_WRAPPER_CLASS,
  ERROR_CLASS } from '@/service/constants/simulationForm';

type ScrollBehavior = 'auto' | 'smooth';
type ScrollAligment = 'start' | 'center' | 'end' | 'nearest';

class ScrollOptions {
  horizontalAlign?: ScrollAligment = 'start';
  verticalAlign?: ScrollAligment = 'start';
  offsetY?: number = 0;
  offsetX?: number = 0;
  behavior?: ScrollBehavior = 'smooth';
  rootSelector?: string = '';
};

export const APP_CONTAINER_SELECTOR = '.v-application';
export const APP_BAR_CLASS = 'app-navigation';
export const APP_BAR_SELECTOR = `.${APP_BAR_CLASS}`;
export const BOTTOM_BAR_CLASS = 'navbar-menu';
export const BOTTOM_BAR_SELECTOR = `.${BOTTOM_BAR_CLASS}`;

export default function useScrollTo() {
  const scrollTo = (selector: string, options: ScrollOptions = new ScrollOptions()) => {
    const element = document.querySelector<HTMLElement>(selector);
    scrollToElement(element, options);
  };

  const scrollToCenter = (selector: string, options: ScrollOptions = new ScrollOptions()) => {
    scrollTo(selector, {...options, verticalAlign: 'center',});
  };
  
  const scrollToNearestEdge = (selector: string, options: ScrollOptions = new ScrollOptions()) => {
    scrollTo(selector, {...options, verticalAlign: 'nearest',});
  };
  
  const scrollToBottom = (selector: string, options: ScrollOptions = new ScrollOptions()) => {
    scrollTo(selector, {...options, verticalAlign: 'end',});
  };
  
  const scrollAfter = (selector: string, options: ScrollOptions = new ScrollOptions()) => {
    const element = document.querySelector<HTMLElement>(selector);
    options.offsetY += element?.getBoundingClientRect().height || 0;
    scrollToElement(element, options);
  };

  const scrollBefore = (selector: string, options: ScrollOptions = new ScrollOptions()) => {
    const element = document.querySelector<HTMLElement>(selector);
    const appBarElement = document.querySelector(APP_BAR_SELECTOR);
    const appBarHeight = appBarElement?.getBoundingClientRect().height || 0;
    options.offsetY -= window.innerHeight - appBarHeight;
    scrollToElement(element, options);
  };

  const scrollToElement = (element: HTMLElement, options: ScrollOptions = new ScrollOptions()) => {
    if (!element) {
      return;
    }

    options = {...(new ScrollOptions()), ...options,};
    const elementRect = element.getBoundingClientRect();
    const scrollContainer = (options.rootSelector && document.querySelector(options.rootSelector)) || document.documentElement;
    const containerRect = scrollContainer.getBoundingClientRect();
    const containerTop = options.rootSelector ? containerRect.top : 0;
    const screenHeight = options.rootSelector ? scrollContainer.getBoundingClientRect().height : window.innerHeight;

    let top = elementRect.top - containerTop + options.offsetY + scrollContainer.scrollTop;
    if (options.verticalAlign === 'nearest') {
      options.verticalAlign = Math.abs(elementRect.top) < Math.abs(elementRect.bottom - screenHeight) ? 'start' : 'end';
    }
    
    if (options.verticalAlign === 'start') {
      const appBarElement = scrollContainer.querySelector(APP_BAR_SELECTOR);
      const appBarHeight = appBarElement?.getBoundingClientRect().height || 0;
      top -= appBarHeight;
    } else if (options.verticalAlign === 'end') {
      const bottomNavigationEleemnt = scrollContainer.querySelector(APP_BAR_SELECTOR);
      const bottomNavigationRect = bottomNavigationEleemnt?.getBoundingClientRect();
      if (bottomNavigationRect && bottomNavigationRect.top > 0) {
        const bottomNavigationHeight = bottomNavigationRect.height || 0;
        top += elementRect.height - screenHeight + bottomNavigationHeight;
      }
    } else if (options.verticalAlign === 'center') {
      top += elementRect.height > screenHeight ? (elementRect.height - screenHeight) / 2 : -screenHeight / 2 + elementRect.height / 2;
    }

    scrollContainer.scrollTo({
      top,
      behavior: options.behavior,
    });
  };

  const scrollToError = (selector: string = ERROR_CLASS, options: ScrollOptions = new ScrollOptions()) => {
    scrollTo(selector, options);
  };
  
  const scrollToTop = (options: ScrollOptions = new ScrollOptions()) => {
    scrollTo(APP_CONTAINER_SELECTOR, options);
  };
  
  const scrollToSimulationFormError = (selector: string = ERROR_CLASS, options: ScrollOptions = new ScrollOptions()) => {
    const element = document.querySelector<HTMLElement>(selector);
    const formFieldElement: HTMLElement | null = element ? element.closest(`.${FORM_FIELD_CLASS}`) : null;
    const firstRowElement: HTMLElement | null = element ? element.closest(`.${FIRST_ROW_CLASS}, .${FORM_ROW_TITLE_CLASS} + .${FORM_FIELD_WRAPPER_CLASS}`) : null;
    if (firstRowElement) {
      const rowElement: HTMLElement = firstRowElement.closest(`.${FORM_ROW_CLASS}`);
      scrollToElement(rowElement, options);
    } else if (formFieldElement) {
      scrollToElement(formFieldElement, options);
    } else {
      scrollTo(selector, options);
    }
  };

  return {
    scrollAfter,
    scrollBefore,
    scrollTo,
    scrollToCenter,
    scrollToNearestEdge,
    scrollToBottom,
    scrollToElement,
    scrollToError,
    scrollToTop,
    scrollToSimulationFormError,
  };
}